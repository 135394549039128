import { startOfDay } from 'date-fns';
import { ApiModels } from '@mona/api';
import {
    Allergy,
    Anamnesis,
    BalanceTarget,
    BasicCareProcedure,
    ChangeLogEntry,
    ChangeLogModel,
    ChangeLogModelKey,
    Diagnosis,
    Encounter,
    EncounterHistoryEntry,
    EntryControl,
    InfectionStatus,
    LabValue,
    MedicationAdministration,
    MedicationPrescription,
    Patient,
    PatientOutput,
    PhysicalExamination,
    PreMedication,
    ProcedurePrescription,
    SurgeryPrescription,
    TherapyLimitations,
    VaccinationStatus,
    Valuables,
    VentilationParameter,
    VentilationProcedure,
    VitalSign,
    WoundStatus,
} from '@mona/models';
import { isEmpty } from '@mona/shared/utils';

/**
 * Transforms API change log entries
 *
 * @param entries ApiModels.ChangeLogEntry[]
 */
export const transformApiChangeLogEntries = (entries: ApiModels.ChangeLogEntry[]): ChangeLogEntry<ChangeLogModel>[] => {
    return entries.map(entry => transformApiChangeLogEntry(entry));
};

/**
 * Transforms API Lab Value
 *
 * @param apiLabValue ApiModels.LabValue
 */
export const transformApiLabValues = (apiLabValue: ApiModels.LabValue): LabValue => {
    const model = ApiModels.LabValue.DTO.toModel(apiLabValue);
    return model;
};

/**
 * Transforms medication prescription
 *
 * @param apiMedicationPrescription ApiModels.MedicationPrescription
 */
export const transformApiMedicationPrescription = (
    apiMedicationPrescription: ApiModels.MedicationPrescription,
): MedicationPrescription => {
    const model = ApiModels.MedicationPrescription.DTO.toModel(apiMedicationPrescription);
    return model;
};

/**
 * Transforms ventilation procedure
 *
 * @param apiVentilationParameter ApiModels.VentilationProcedure
 */
export const transformApiVentilationProcedure = (
    apiVentilationParameter: ApiModels.VentilationProcedure,
): VentilationProcedure => {
    const model = ApiModels.VentilationProcedure.DTO.toModel(apiVentilationParameter) as VentilationProcedure;
    return model;
};

/**
 * Transforms patient
 *
 * @param apiPatient ApiModels.Patient
 */
export const transformApiPatient = (apiPatient: ApiModels.Patient): Patient => {
    const model = ApiModels.Patient.DTO.toModel(apiPatient);
    return model;
};

/**
 * Transforms anamnesis
 *
 * @param apiAnamnesis ApiModels.Anamnesis
 */
export const transformApiAnamnesis = (apiAnamnesis: ApiModels.Anamnesis): Anamnesis => {
    const model = ApiModels.Anamnesis.DTO.toModel(apiAnamnesis);
    return model;
};

/**
 * Transformі vital sign
 *
 * @param apiVitalSign ApiModels.VitalSign
 */
export const transformApiVitalSign = (apiVitalSign: ApiModels.VitalSign): VitalSign => {
    const model = ApiModels.VitalSign.DTO.toModel(apiVitalSign);
    return model;
};

/**
 * Transform api Infection status
 *
 * @param apiInfectionStatus ApiModels.InfectionStatus
 */
export const transformApiInfectionStatus = (apiInfectionStatus: ApiModels.InfectionStatus): InfectionStatus => {
    const model = ApiModels.InfectionStatus.DTO.toModel(apiInfectionStatus);
    return model;
};

/**
 * Transform api Wound status
 *
 * @param apiWoundStatus ApiModels.WoundStatus
 */
export const transformApiWoundStatus = (apiWoundStatus: ApiModels.WoundStatus): WoundStatus => {
    const model = ApiModels.WoundStatus.DTO.toModel(apiWoundStatus);
    return model;
};

/**
 * Transform api surgery prescription
 *
 * @param apiSurgeryPrescription ApiModels.SurgeryPrescription
 */
export const transformApiSurgeryPrescription = (
    apiSurgeryPrescription: ApiModels.SurgeryPrescription,
): SurgeryPrescription => {
    const model = ApiModels.SurgeryPrescription.DTO.toModel(apiSurgeryPrescription);
    return model;
};

/**
 * Transform api vaccination status
 *
 * @param apiVaccinations ApiModels.VaccinationStatus
 */
export const transformApiVaccinationStatus = (apiVaccinations: ApiModels.VaccinationStatus): VaccinationStatus => {
    const model = ApiModels.VaccinationStatus.DTO.toModel(apiVaccinations);
    return model;
};

/**
 * Transform api TherapyLimitations status
 *
 * @param apiTherapyLimitations ApiModels.TherapyLimitations
 */
export const transformApiTherapyLimitations = (
    apiTherapyLimitations: ApiModels.TherapyLimitations,
): TherapyLimitations => {
    const model = ApiModels.TherapyLimitations.DTO.toModel(apiTherapyLimitations);
    return model;
};

/**
 * Transform api Valuables status
 *
 * @param apiValuables ApiModels.Valuables
 */
export const transformApiValuables = (apiValuables: ApiModels.Valuables): Valuables => {
    const model = ApiModels.Valuables.DTO.toModel(apiValuables);
    return model;
};

/**
 * Transformsd allergy
 *
 * @param apiAllergy ApiModels.Allergy
 */
export const transformApiAllergy = (apiAllergy: ApiModels.AllergyIntolerance): Allergy => {
    const model = ApiModels.AllergyIntolerance.DTO.toModel(apiAllergy);
    return model;
};

/**
 * Transforms diagnosis
 *
 * @param apiDiagnosis ApiModels.Diagnosis
 */
export const transformApiDiagnosis = (apiDiagnosis: ApiModels.Diagnosis): Diagnosis => {
    const model = ApiModels.Diagnosis.DTO.toModel(apiDiagnosis) as Diagnosis;
    return model;
};

/**
 * Transforms pre medication
 *
 * @param apiPreMedication ApiModels.PreMedication
 */
export const transformApiPreMedication = (apiPreMedication: ApiModels.Premedication): PreMedication => {
    const model = ApiModels.Premedication.DTO.toModel(apiPreMedication) as PreMedication;
    return model;
};

/**
 * Transforms physical examination
 *
 * @param apiPhysicalExamination ApiModels.PhysicalExamination
 */
export const transformApiPhysicalExamination = (
    apiPhysicalExamination: ApiModels.PhysicalExamination,
): PhysicalExamination => {
    const model = ApiModels.PhysicalExamination.DTO.toModel(apiPhysicalExamination);
    return model;
};

/**
 * Transforms encounter history entry
 *
 * @param apiHistory ApiModels.History
 */
export const transformApiHistory = (apiHistory: ApiModels.PatientHistory): EncounterHistoryEntry => {
    const model = ApiModels.PatientHistory.DTO.toModel(apiHistory) as EncounterHistoryEntry;
    model.text = apiHistory.value;
    return model;
};

/**
 * Transforms encounter
 *
 * @param apiEncounter ApiModels.Encounter
 */
export const transformApiEncounter = (apiEncounter: ApiModels.Encounter): Encounter => {
    const model = ApiModels.Encounter.DTO.toModel(apiEncounter);
    return model;
};

/**
 * Transforms procedure prescription
 *
 * @param apiProcedurePrescription ApiModels.ProcedurePrescription
 */
export const transformApiProcedurePrescription = (
    apiProcedurePrescription: ApiModels.ProcedurePrescription,
): ProcedurePrescription => {
    const model = ApiModels.ProcedurePrescription.DTO.toModel(apiProcedurePrescription);
    return model;
};

/**
 * Transforms output
 *
 * @param apiOutput ApiModels.Output
 */
export const transformApiOutput = (apiOutput: ApiModels.Output): PatientOutput => {
    const model = ApiModels.Output.DTO.toModel(apiOutput);
    return model;
};

/**
 * Transforms medication administration
 *
 * @param apiMedicationAdministration ApiModels.MedicationAdministration
 */
export const transformApiMedicationAdministration = (
    apiMedicationAdministration: ApiModels.MedicationAdministration,
): MedicationAdministration => {
    const model = ApiModels.MedicationAdministration.DTO.toModel(
        apiMedicationAdministration,
    ) as MedicationAdministration;
    return model;
};

/**
 * Transforms balance target
 *
 * @param apiBalanceTarget ApiModels.BalanceTarget
 */
export const transformApiBalanceTarget = (apiBalanceTarget: ApiModels.BalanceTarget): BalanceTarget => {
    const model = ApiModels.BalanceTarget.DTO.toModel(apiBalanceTarget);
    return model;
};

/**
 * Transforms basic care procedure
 *
 * @param apiBasicCareProcedure ApiModels.BasicCareProcedure
 */
export const transformApiBasicCareProcedure = (apiBasicCareProcedure: ApiModels.BasicCare): BasicCareProcedure => {
    const model = ApiModels.BasicCare.DTO.toModel(apiBasicCareProcedure) as BasicCareProcedure;
    return model;
};

/**
 * Transforms procedure
 *
 * @param apiProcedure ApiModels.Procedure
 */
export const transformApiProcedure = (apiProcedure: ApiModels.Procedure) => {
    const model = ApiModels.Procedure.DTO.toModel(apiProcedure);
    return model;
};

/**
 * Transforms api patient history
 *
 * @param apiPatientHistory ApiModels.PatientHistory
 */
export const transformApiPatientHistory = (apiPatientHistory: ApiModels.PatientHistory): EncounterHistoryEntry => {
    const model = ApiModels.PatientHistory.DTO.toModel(apiPatientHistory) as EncounterHistoryEntry;
    model.text = apiPatientHistory.value;

    return model;
};

/**
 * Transforms entry control
 *
 * @param apiEntryControl ApiModels.EntryControl
 */
export const transformApiEntryControl = (apiEntryControl: ApiModels.EntryControl): EntryControl => {
    let model = ApiModels.EntryControl.DTO.toModel(apiEntryControl);
    // TODO: The logic bellow should be made by BE, remove it when will be done by BE
    model = {
        ...model,
        cuffCmH2o: model['cuffCmH2O'],
        careChecks: model?.careChecks?.map(careCheck => ({
            ...careCheck,
            installationDate: startOfDay(careCheck.installationDate),
            lastChange: startOfDay(careCheck.lastChange),
        })),
    };
    delete model['cuffCmH2O'];

    return model;
};

/**
 * Transforms workflow
 *
 * @param apiWorkflow ApiModels.WorkflowQuestionnaireResponse
 */
export const transformApiWorkflow = (apiWorkflow: ApiModels.WorkflowQuestionnaireResponse) => {
    const model = ApiModels.WorkflowQuestionnaireResponse.DTO.toModel(apiWorkflow);
    return model;
};

/**
 * Transforms DailyGoal
 *
 * @param apiDailyGoal ApiModels.DailyGoal
 */
export const transformApiDailyGoal = (apiDailyGoal: ApiModels.DailyGoal) => {
    return ApiModels.DailyGoal.DTO.toModel(apiDailyGoal);
};

/**
 * Transforms API change log entry
 *
 * @param apiModel ApiModels.ChangeLogEntry
 */
export const transformApiChangeLogEntry = (apiModel: ApiModels.ChangeLogEntry): ChangeLogEntry<ChangeLogModel> => {
    let payload = null;
    const payloadTransformerMap = new Map<ChangeLogModelKey, AnyFn>([
        ['Patient', transformApiPatient],
        ['Anamnesis', transformApiAnamnesis],
        ['VitalSign', transformApiVitalSign],
        ['InfectionStatus', transformApiInfectionStatus],
        ['WoundStatus', transformApiWoundStatus],
        ['SurgeryPrescription', transformApiSurgeryPrescription],
        ['VaccinationStatus', transformApiVaccinationStatus],
        ['TherapyLimitations', transformApiTherapyLimitations],
        ['Valuables', transformApiValuables],
        ['AllergyIntolerance', transformApiAllergy],
        ['DailyGoal', transformApiDailyGoal],
        ['Diagnosis', transformApiDiagnosis],
        ['PreMedication', transformApiPreMedication],
        ['PhysicalExamination', transformApiPhysicalExamination],
        ['PatientHistory', transformApiHistory],
        ['Encounter', transformApiEncounter],
        ['LabValue', transformApiLabValues],
        ['VentilationParameter', transformPartialApiVentilationParameter], // NO DTO
        ['VentilationProcedure', transformApiVentilationProcedure],
        ['MedicationPrescription', transformApiMedicationPrescription],
        ['ProcedurePrescription', transformApiProcedurePrescription],
        ['Output', transformApiOutput],
        ['MedicationAdministration', transformApiMedicationAdministration],
        ['BalanceTarget', transformApiBalanceTarget],
        ['BasicCare', transformApiBasicCareProcedure],
        ['Procedure', transformApiProcedure],
        ['EntryControl', transformApiEntryControl],
        ['WorkflowQuestionnaireResponse', transformApiWorkflow],
    ]);

    if (!isEmpty(apiModel.payload)) {
        payload = payloadTransformerMap.get(apiModel.model)?.(apiModel.payload);
    }

    const model = ApiModels.ChangeLogEntry.DTO.toModel(apiModel);
    model.payload = payload;
    return model;
};

// FIXME: Delete this transform fn after fixing API Model generation
/**
 * Transforms partial ventilation parameter
 *
 * @param apiVentilationParameter Partial<ApiModels.PdmsVentilationParameter>
 */
export const transformPartialApiVentilationParameter = (
    apiVentilationParameter: Partial<ApiModels.PdmsVentilationParameter>,
): Partial<VentilationParameter> => {
    const { value, code, date, unit } = apiVentilationParameter;
    const ventilationParameter: Partial<VentilationParameter> = {};

    if (value || +value === 0) {
        ventilationParameter.value = value;
    }

    if (date) {
        ventilationParameter.date = new Date(date);
    }

    if (code) {
        ventilationParameter.code = code;
    }

    if (unit) {
        ventilationParameter.unit = unit;
    }

    return ventilationParameter;
};
