/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { Practitioner } from '@mona/models';
import { AppError } from '@mona/shared/utils';
import { TokenResponse, AuthFlow, AuthTypeEnum, User } from '../../models';

/**
 * Auth actions
 */

export const scanRfid = createAction('AUTH:RFID_SCAN', props<{ rfid: string }>());
export const emulateRfid = createAction('AUTH:RFID_EMULATE', props<{ rfid: string }>());

export const updateRfid = createAction('AUTH:UPDATE_RFID');
export const updateRfidSuccess = createAction('AUTH:UPDATE_RFID_SUCCEEDED', props<{ rfid: string }>());
export const updateRfidFailure = createAction('AUTH:UPDATE_RFID_FAILED', props<{ error: unknown }>());

export const registerRfid = createAction('AUTH:REGISTER_RFID', props<{ rfid: string; pin: string }>());
export const registerRfidSuccess = createAction('AUTH:REGISTER_RFID_SUCCEEDED', props<{ user: Practitioner }>());
export const registerRfidFailure = createAction('AUTH:REGISTER_RFID_FAILED', props<{ error: unknown }>());

export const discardRfid = createAction('AUTH:DISCARD_RFID');
export const discardRfidSuccess = createAction('AUTH:DISCARD_RFID_SUCCEEDED');
export const discardRfidFailure = createAction('AUTH:DISCARD_RFID_FAILED', props<{ error: unknown }>());

export const changePassword = createAction(
    'AUTH:CHANGE_PASSWORD',
    props<{ oldPassword: string; newPassword: string; username: string }>(),
);
export const changePasswordSuccess = createAction('AUTH:CHANGE_PASSWORD_SUCCEEDED');
export const changePasswordFailure = createAction('AUTH:CHANGE_PASSWORD_FAILED', props<{ error: AppError }>());

export const authenticateOpen = createAction(
    'AUTH:AUTHENTICATE_DIALOG_OPEN',
    props<{ authType?: AuthTypeEnum; authFlow?: AuthFlow; additionalMessage?: string }>(),
);
export const authenticateClose = createAction('AUTH:AUTHENTICATE_DIALOG_CLOSE', props<{ user?: User }>());

export const logOut = createAction(
    'AUTH:LOGOUT',
    props<{
        error?: any;
        isRelogin?: boolean;
        skipDiscardDialog?: boolean;
        isUserChangedDuringPersist?: boolean;
        fromRfidManagement?: boolean;
    }>(),
);
export const logOutSuccess = createAction('AUTH:LOGOUT_SUCCESS');
export const relogIn = createAction('AUTH:RELOGIN');

/**
 * Login with creds or rfid
 */
export const logInWithCredentials = createAction(
    'AUTH:LOGIN_WITH_CREDENTIALS',
    props<{
        payload: {
            username: string;
            password: string;
        };
        isRelogin?: boolean;
        authFlow?: AuthFlow;
    }>(),
);
export const logInWithRfid = createAction(
    'AUTH:LOGIN_WITH_RFID',
    props<{
        payload: {
            rfid: string;
        };
        isRelogin?: boolean;
        authFlow?: AuthFlow;
    }>(),
);
export const loginSuccess = createAction('AUTH:LOGIN_SUCCESS', props<{ authFlow?: AuthFlow }>());
export const loginFailure = createAction('AUTH:LOGIN_FAILURE', props<{ error: unknown; authFlow?: AuthFlow }>());

export const checkToken = createAction('AUTH:CHECK_TOKEN');

/**
 *  Refresh token
 */
export const refreshTokenRequest = createAction('AUTH:REFRESH_TOKEN');
export const refreshTokenSuccess = createAction('AUTH:REFRESH_TOKEN_SUCCESS', props<TokenResponse>());
export const refreshTokenFailure = createAction(
    'AUTH:REFRESH_TOKEN_FAILURE',
    props<{ error: unknown; authFlow?: AuthFlow }>(),
);

/**
 * Auth User: me
 */
export const setAuthUser = createAction('AUTH:SET_USER', props<{ user: User; skipWelcome?: boolean }>());

export const loadPermissions = createAction('AUTH:LOAD_PERMISSIONS');
export const loadPermissionsSuccess = createAction('AUTH:LOAD_PERMISSIONS_SUCCESS', props<{ permissions: string[] }>());
export const loadPermissionsFailed = createAction('AUTH:LOAD_PERMISSIONS_FAILED', props<{ error?: unknown }>());

export const clearAuthError = createAction('AUTH:CLEAR_ERROR');
