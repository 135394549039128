import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Encounter, MonaNotification, NotificationResolution } from '@mona/models';
import { AppState, AsyncActionState, selectSelectedId, RouterStateSerialized, selectRouteParam } from '@mona/store';
import { NotificationsAction, NotificationsSelectors } from '../state';

/**
 * Notifications store service
 */
@Injectable({ providedIn: 'root' })
export class NotificationsService {
    /**
     * Constructor
     *
     * @param store store
     */
    constructor(private store: Store<AppState>) {}

    //#region Selectors

    /**
     * Get current encounter from route
     */
    getCurrentEncounterFromRoute(): Observable<string> {
        return this.store.select<any>(selectRouteParam('encounterId'));
    }

    /**
     * Get load notifications async action
     */
    getLoadNotificationsAction(): Observable<AsyncActionState<MonaNotification[]>> {
        return this.store.select(NotificationsSelectors.selectLoadNotificationsAction);
    }

    /**
     * Get resolve notification async action
     */
    getResolveNotificationAction(): Observable<AsyncActionState<MonaNotification>> {
        return this.store.select(NotificationsSelectors.selectResolveNotificationAction);
    }

    /**
     * Get notifications
     */
    getNotifications(): Observable<MonaNotification[]> {
        return this.store.select(NotificationsSelectors.selectNotifications);
    }

    /**
     * Get notifications of encounter
     *
     * @param encounterId EntityId<Encounter>
     */
    getEncounterNotifications(encounterId?: EntityId<Encounter>): Observable<MonaNotification[]> {
        return this.store.select(NotificationsSelectors.selectNotifications);
    }

    /**
     * Get encounter notifications count
     *
     * @param encounterId EntityId<Encounter>
     */
    getEncounterNotificationsCount(encounterId?: EntityId<Encounter>): Observable<number> {
        return this.store.select(NotificationsSelectors.selectNotificationsCount);
    }

    //#endregion Selectors

    //#region Actions

    /**
     * Triggers notifications loading
     *
     * @param encounterId EntityId<Encounter>
     */
    loadNotifications(encounterId: EntityId<Encounter>) {
        this.store.dispatch(NotificationsAction.loadNotificationsAction.action({ encounterId }));
    }

    /**
     * Add/update notification
     *
     * @param notification MonaNotification
     */
    addUpdateNotification(notification: MonaNotification) {
        this.store.dispatch(NotificationsAction.addUpdateNotificationAction({ payload: notification }));
    }

    /**
     * Resolves notification
     *
     * @param notification MonaNotification
     * @param resolution NotificationResolution
     */
    resolveNotification(notification: MonaNotification, resolution: NotificationResolution): void {
        this.store.dispatch(NotificationsAction.resolveNotificationAction.action({ notification, resolution }));
    }

    /**
     * Clears notifications
     */
    clearNotifications(): void {
        this.store.dispatch(NotificationsAction.loadNotificationsAction.clearAction());
    }

    /**
     * Clears notifications
     */
    clearResolveNotificationAction(): void {
        this.store.dispatch(NotificationsAction.resolveNotificationAction.clearAction());
    }
    //#endregion Actions
}
